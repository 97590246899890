const LogisticsOfficerMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Maintenances',
    name: 'logistics-officer.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-sliders',
    children: [
      {
        title: 'Companies',
        is_heading: false,
        is_active: false,
        link: {
          name: 'logistics-officer.maintenances.companies'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'fat fa-city',
        active_icon: 'fad fa-city'
      },
      {
        title: 'Organizations',
        is_heading: false,
        is_active: false,
        link: {
          name: 'logistics-officer.maintenances.organizations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'fat fa-people-roof',
        active_icon: 'fad fa-people-roof'
      },
      {
        title: 'Vehicle Types',
        is_heading: false,
        is_active: false,
        link: {
          name: 'logistics-officer.maintenances.vehicle-types'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'fat fa-car-garage',
        active_icon: 'fad fa-car-garage'
      },
      {
        title: 'Vehicles',
        is_heading: false,
        is_active: false,
        link: {
          name: 'logistics-officer.maintenances.vehicles'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'fat fa-car',
        active_icon: 'fad fa-car'
      },
      {
        title: 'Drivers',
        is_heading: false,
        is_active: false,
        link: {
          name: 'logistics-officer.maintenances.drivers'
        },
        class_name: 'mb-3',
        is_icon_class: true,
        icon: 'fat fa-user-pilot',
        active_icon: 'fad fa-user-pilot'
      }
    ]
  },
  {
    title: 'Reservation Approvals',
    is_heading: true,
    is_active: false,
    link: {
      name: 'logistics-officer.reservations'
    },
    class_name: 'font-weight-bolder mt-3'
  },
  {
    title: 'Pending',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.reservations.pending'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-file',
    active_icon: 'fad fa-file'
  },
  {
    title: 'Histories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.reservations.histories'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-clock-rotate-left',
    active_icon: 'fad fa-clock-rotate-left'
  },
  {
    title: 'Vehicle Schedules',
    is_heading: true,
    is_active: false,
    link: {
      name: 'logistics-officer.vehicle-schedules'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Pending',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.vehicle-schedules.pending'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-car',
    active_icon: 'fad fa-car'
  },
  {
    title: 'Histories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.vehicle-schedules.histories'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-clock-rotate-left',
    active_icon: 'fad fa-clock-rotate-left'
  },
  {
    title: 'Driver Schedules',
    is_heading: true,
    is_active: false,
    link: {
      name: 'logistics-officer.driver-schedules'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Pending',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.driver-schedules.pending'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-user-pilot',
    active_icon: 'fad fa-user-pilot'
  },
  {
    title: 'Histories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.driver-schedules.histories'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fat fa-clock-rotate-left',
    active_icon: 'fad fa-clock-rotate-left'
  }
]

export default LogisticsOfficerMenu
